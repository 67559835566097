<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-6">
      <div class="container-fluid d-flex">
        <div class="row">
          <h3 class="display-4 text-white">Welcome {{ user.name }}</h3>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <top-section :stats="stats"></top-section>
      <!--Tables-->
      <div class="row">
        <div class="col-xl-12">
          <my-valuations :valuations="valuations"></my-valuations>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>
<script>
import api from "../api/api";
import MyValuations from "./Dashboard/MyValuations";
import TopSection from "./Dashboard/TopSection";

export default {
  data() {
    return {
      valuations: [],
      stats: {
        count7d: 0,
        count14d: 0,
      },
    };
  },
  components: {
    MyValuations,
    TopSection,
  },
  computed: {
    user() {
      return this.$store.getters.loginUser;
    },
  },
  methods: {
    countItemsWithinDateRange(array, startDate, endDate) {
      const itemsWithinDateRange = array.filter((item) => {
        const itemDate = new Date(item.updatedAt);
        return itemDate >= startDate && itemDate <= endDate;
      });
      return itemsWithinDateRange.length;
    },  
    fetchData() {
      api.getValuationsByUser(this.user.id).then((data) => {
        let items = data.data.listValuations.items;
        let sitems = items.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
        this.valuations = sitems.slice(0, 10);

        const today = new Date();
        const last7Days = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const last14Days = new Date(today.getTime() - 24 * 24 * 60 * 60 * 1000);
        const totalItemsWithinLast7Days = this.countItemsWithinDateRange(items, last7Days, today);
        const totalItemsBetween7And14DaysAgo = this.countItemsWithinDateRange(items, last14Days, last7Days);
        this.stats = {
          count7d: totalItemsWithinLast7Days,
          count14d: totalItemsBetween7And14DaysAgo,
        };
      });
    },

  },
  created() {
    this.fetchData();
  },
};
</script>
<style scoped>
.divider {
  margin: 20px;
}
</style>
