<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="min-height: 200px; background-size: cover; background-position: center top;"
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">New Valuation</h3>
                </div>
              </div>
            </div>
            <div v-if="message">
              <base-alert :type="message.type">{{
                message.message
              }}</base-alert>
            </div>
            <template>
              <form @submit.prevent>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      Property address
                      <vue-single-select
                              v-model="valuation.address"
                              :options=getLocations()
                              :required="true"
                      ></vue-single-select>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: 20px">
                    <div class="col-lg-2">
                      <label class="form-control-label select-label">Transaction</label>
                      <base-dropdown>
                        <template v-slot:title>
                          <base-button type="secondary" class="dropdown-toggle">
                           {{ valuation.transaction.title }}
                          </base-button>
                        </template>
                          <div v-for="item in transactionsTypesList" v-on:click="assignTransaction(item)" :key="item.id" class="dropdown-item">{{ item.title }}</div>
                      </base-dropdown>
                    </div>
                    <div class="col-lg-2">
                      <label class="form-control-label select-label">Type</label>
                      <base-dropdown>
                        <template v-slot:title>
                          <base-button type="secondary" class="dropdown-toggle">
                            {{ valuation.type.title }}
                          </base-button>
                        </template>
                          <div v-for="item in valuationsTypesList" v-on:click="assignType(item)" :key="item.id" class="dropdown-item">{{ item.title }}</div>
                      </base-dropdown>
                    </div>                    
                    <div class="col-lg-2">
                      <label class="form-control-label select-label">Rooms</label>
                      <base-dropdown>
                        <template v-slot:title>
                          <base-button type="secondary" class="dropdown-toggle">
                            {{ valuation.rooms.title }}
                          </base-button>
                        </template>
                        <div v-for="room in roomsList" v-on:click="assignRooms(room)" :key="room.id" class="dropdown-item">{{ room.title }}</div>
                      </base-dropdown>
                    </div>

                    <label class="form-control-label"></label>

                    <div class="col-lg-2">
                      <label class="form-control-label select-label">Baths</label>
                      <base-dropdown>
                        <template v-slot:title>
                          <base-button type="secondary" class="dropdown-toggle">
                          {{ valuation.baths.title }}
                          </base-button>
                        </template>
                        <div v-for="bath in bathsList" v-on:click="assignBaths(bath)" :key="bath.id" class="dropdown-item">{{ bath.title }}</div>
                      </base-dropdown>
                    </div>                    
                    <div class="col-lg-2">
                      <base-input
                        alternative=""
                        label="Area(sqft)"
                        type="number"
                        maxlength = "5"
                        input-classes="form-control-alternative"
                        v-model="valuation.area"
                      />
                    </div>
                    <div class="col-lg-2"  v-if="valuation.type.id==='Apartment'">
                      <base-input
                        alternative=""
                        label="Level"
                        type="number"
                        maxlength = "3"
                        input-classes="form-control-alternative"
                        v-model="valuation.level"
                      />
                    </div>                    
                    <div class="col-lg-2">
                      <button v-on:click="createValuation" class="btn btn-info">
                        Calculate
                      </button>
                    </div>
                  </div>

                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "./../api/api";
import redsapi from "./../api/redsapi";
import VueSingleSelect from "vue-single-select";

export default {
  name: "create-valuation",
  data() {
    return {
      valuation: {
        address:"",
        area:"1000",
        level:"1",
        title: "",
        transaction:"",
        type:null,

      },
      message: null,
      step: 0,
      valuationId: "",
    };
  },
  components: {
     VueSingleSelect
  },
  methods: {   
    async createValuation() {

      this.message = null;

      if (!this.validateForm()) {
        this.message = {
          message: "Validation failed." ,
          type: "danger",
        };
      } else {
        let valuationToSave = {
          title: this.valuation.address,
          address: this.valuation.address,
          purpose: this.valuation.transaction.id,
          type: this.valuation.type.id,
          rooms: Number(this.valuation.rooms.id),
          baths: Number(this.valuation.baths.id),
          area: Number(this.valuation.area),
          valuationUserId: this.user.id,
          userId: this.user.id,
          level: Number(this.valuation.level),
        }

        await this.checkExistingValuation(valuationToSave);
        if (this.valuationId != '') {
          this.$router.push('/view-valuation/' + this.valuationId);
          return;
        }

        api
          .createValuation(valuationToSave)
          .then((data) => {

            console.log(data.data.createValuation);
            this.valuationId = data.data.createValuation.id;
            this.processValuation();
            this.message = {
              message: "Valuation created successfully." + data.data.createValuation.id,
              type: "success",
            };
            this.valuation = {
              title: "",
            };
            
            this.progress();
          })
          .catch((e) => {
            console.log(e);
            this.message = {
              message: "Failed to create new valuation.",
              type: "danger",
            };
          });
      }
    },
    processValuation() {

      var currentDate = new Date();
      let valuationParams = {
          id: this.valuationId,
          address: this.valuation.address,
          purpose: this.valuation.transaction.id,
          property_type: this.valuation.type.id,
          beds: Number(this.valuation.rooms.id),
          baths: Number(this.valuation.baths.id),
          area: Number(this.valuation.area),
          level: Number(this.valuation.level),
          userId: this.user.id,
          companyId: this.user.companyId,
          date: currentDate,
        }
      redsapi.processValuation(valuationParams).then(() => {
        this.$router.push('/view-valuation/' + this.valuationId);
      })

    },    
    async checkExistingValuation(valuation) {
      await api.getValuationsByUser(this.user.id).then((data) => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        let val = data.data.listValuations.items.filter(item => 
                                item.address===valuation.address 
                                && item.purpose===valuation.purpose
                                && item.type===valuation.type
                                && item.rooms===valuation.rooms
                                && item.baths===valuation.baths
                                && item.area===valuation.area
                                && item.updatedAt.slice(0,10) == formattedDate
                                )
        this.valuationId = val.length>0 ? val[0].id : "";
      });
    },    
    validateForm() {
      return (
        this.valuation.address.trim() != "" &&
        this.valuation.type != "" &&
        this.valuation.area != ""
      );
    },
    assignTransaction(value) {
      this.valuation.transaction = value
    },
    assignType(type) {
      this.valuation.type = type
    },    
    assignRooms(rooms) {
      this.valuation.rooms = rooms
      this.defaultArea()
    },    
    assignBaths(baths) {
      this.valuation.baths = baths
      this.defaultArea()
    },      
    defaultArea() {
      this.valuation.area = 600 + this.valuation.rooms.id * 200 + this.valuation.baths.id * 100;
    },
    getLocations() {
      const locations = require('../assets/locations.json');
      return locations;
    },
    progress() {
      this.step = this.step + 1;
      this.message = {
              message: "Processing valuation. Step " + this.step + "/5... wait a few seconds",
              type: "success",
            };
      if (this.step<5) {
        setTimeout(() => this.progress(), 1500);
      }
      else {
        this.step = 0;
        //setTimeout(() => this.$router.push('/view-valuation/' + this.valuationId), 50)
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.loginUser;
    },
    transactionsTypesList: function() {
      let self = this
      let transactions = this.$store.getters.purposeTypesList
      self.valuation.transaction = transactions[0]
      return transactions
    },    
    valuationsTypesList: function() {
      let self = this
      let types = this.$store.getters.valuationsTypesList
      self.valuation.type = types[0]
      return types
    },
    roomsList: function() {
      let self = this
      let types = this.$store.getters.roomsList
      self.valuation.rooms = types[1]
      return types
    },
    bathsList: function() {
      let self = this
      let types = this.$store.getters.bathsList
      self.valuation.baths = types[1]
      return types
    },

  },
};
</script>
<style scoped>
  .select-label {
    margin-right: 20px;
  }
</style>