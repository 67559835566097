<template>
  <div>
    <base-header type="gradient-success" class="pb-7 pt-5 pt-md-6">
      <div class="container-fluid d-flex">
            <div class="ml-auto" style="margin-bottom: 3px;">
              <base-button size="sm" type="default" outline v-on:click="savePDF()"
                  ><i class="fa fa-edit"></i> Export Pdf {{ this.pdf_status }}
              </base-button>
            </div>
        </div>
    </base-header>

    <div class="container-fluid mt--7 valuation" >
      <div class="row">
          <div class="col-xl-12 order-xl-1">
            
            <iframe ref="myIframe" id="myIframe"
                  :src='this.iframeUrl'
                  frameborder="0"
                  allowtransparency="true"
                  style="width: 0; min-width: 100% !important;" height="2000"
            >
            </iframe>

      </div>
    </div>

    </div>
  </div>
</template>

<script>
import redsapi from "./../api/redsapi";
import { saveAs } from 'file-saver';
import api from "../api/api";

export default {
  name: "view-valuation",
  data() {
    return {
      valuation: null,
      pdf_status: ""
    };
  },

  methods: {

    savePDF() {
        let valuationId = this.$route.params.id;
        this.pdf_status = " - Generating pdf ...";
        redsapi.generatePdf(valuationId)
        .then(data => {
          this.pdf_status = "";
          const fileUrl = 'https://sprintdashboard-storage-9898ef7f184842-app.s3.me-south-1.amazonaws.com/p/' +  data.valuation;
          saveAs(fileUrl, this.getFilename());
        })
        .catch(error => console.error(error));
    },
    getFilename() {
      let valuation = this.valuation;
      let filename = `REDS-Valuation-${valuation.address}_${valuation.type}_${valuation.rooms}_${valuation.baths}_${valuation.area}.pdf`
      return filename;
    }

  },
  created() {
    api.getValuationById(this.$route.params.id)
      .then((valuationData) => {
        this.valuation = valuationData.data.getValuation;
      })
  },
  computed: {
    user() {
      return this.$store.getters.loginUser;
    },
    iframeUrl() {
      let valuationId = this.$route.params.id;
      let url = `/property-explorer/main.htm?id=${valuationId}`;
      return url;
    }
  },
};
</script>
<style scoped>

    #loadingvaluation {
      min-height: 1000px;
      height: auto;
    }

</style>

