<template>
  <div class="row">
    <div class="col-xl-6 col-sm-6 mb-xl-0 mb-4">
      <div class="card">
        <div class="card-body p-3">
          <div class="row">
            <div class="col-8">
              <div class="numbers">
                <p class="text-sm mb-0 text-uppercase font-weight-bold">
                  Valuations
                </p>
                <h5 class="font-weight-bolder">
                  Total count
                </h5>
                <p class="mb-0">
                  <span class="text-success text-sm font-weight-bolder">{{ stats.count7d  }}</span>
                  since last week
                </p>
                <p class="mb-0">
                  <span class="text-success text-sm font-weight-bolder">{{ stats.count14d  }}</span>
                  previous week
                </p>                
              </div>
            </div>
            <div class="col-4 text-end">
              <div class="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                <i
                  class="ni ni-money-coins text-lg opacity-10"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-sm-6 mb-xl-0 mb-4">
      <div class="card">
        <div class="card-body p-3">
          <div class="row">
            <div class="col-8">
              <div class="numbers">
                <p class="text-sm mb-0 text-uppercase font-weight-bold">
                  Actions
                </p>
                <h5 class="font-weight-bolder">
                  Quick actions
                </h5>
                <p class="mb-0">
                  <router-link
                      :to="{ name: 'create valuation' }"
                      >New Valuation</router-link
                    >
                    <br/>
                    <router-link
                      :to="{ name: 'my valuations' }"
                      >See all Valuations</router-link
                    >
                </p>
              </div>
            </div>
            <div class="col-4 text-end">
              <div
                class="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle"
              >
                <i
                  class="ni ni-paper-diploma text-lg opacity-10"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "top-section",
  props: ["stats"],
};
</script>
<style></style>
