import { Auth, API } from "aws-amplify"

export default {
  async processValuation(valuation) {
    let apiName = "redsapi";
    //let apiName = "AdminQueries";
    let path = "/processValuation3";
    let data = {
      body: valuation,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, data)
  },

  async generatePdf(valuationId) {

    let apiName = "redsapi";
    //let apiName = "AdminQueries";
    let path = "/generatePdf2";
    let data = {
      body: {
        id: valuationId
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

/*
    //const genpdfUrl = 'https://9cqor6p1hd.execute-api.me-south-1.amazonaws.com/app/generatePdf';
    const genpdfUrl = 'http://localhost:3000' + path;
    fetch(genpdfUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data.body)
    })
*/

    return await API.post(apiName, path, data)
  },

};
