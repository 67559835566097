<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-6">
      <div class="container-fluid d-flex">
        <div class="row"></div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">


      <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">My valuations</h3>
        </div>
        <div class="col text-right">
          <router-link
            :to="{ name: 'create valuation' }"
            class="btn btn-sm btn-primary"
            >New Valuation</router-link
          >
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <base-table
        thead-classes="thead-light"
        :data="valuations"
      >
        <template slot="columns">
          <th>Address</th>
          <th>Transaction</th>
          <th>Type,Rooms,Baths,Surface</th>
          <th>Updated</th>          
          <th></th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <router-link :to="{ name: 'view valuation', params: { id: row.id } }">
            {{ row.address }}
            </router-link>
          </th>
          <td>
            {{ row.purpose }}
          </td>
          <td>
            {{ row.type }},{{ row.rooms }},{{ row.baths }},{{ row.area }}
          </td>
          <td>
            {{ formatDate(row.updatedAt) }}
          </td>            
          <td>
            <router-link :to="{ name: 'view valuation', params: { id: row.id } }">
              <base-button size="sm" type="primary"
                ><i class="fa fa-edit"></i
              ></base-button>
            </router-link>
            &nbsp;
              <base-button size="sm" type="danger" v-on:click="deleteValuationWithConfirmation(row.id)"
                ><i class="fa fa-trash"></i
              ></base-button>
          </td>
        </template>

      </base-table>
    </div>
  </div>


    </div>
  </div>
</template>
<script>
import api from "../api/api";

export default {
  data() {
    return {
      valuations: [],
    };
  },
  methods: {
    fetchData() {
      api.getValuationsByUser(this.user.id).then((data) => {
        let items = data.data.listValuations.items;
        let sitems = items.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);
        this.valuations = sitems;
      });
    },
    formatDate(sdate) {
        return sdate.substring(0,16).replace("T"," ");
      },
    deleteValuation(id) {
      api.deleteValuation(id).then(() => {
            this.fetchData();
          })
    },
    deleteValuationWithConfirmation(id){
      if (confirm('Confirm transaction deletion') == true) {
        this.deleteValuation(id);
      }
    }
  },
  created() {
    this.fetchData();
  },
  computed: {
    user() { 
      return this.$store.getters.loginUser
    },
  },
};
</script>
