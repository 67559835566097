<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">My latest valuations</h3>
        </div>
        <!--div class="col text-right">
          <router-link
            :to="{ name: 'create valuation' }"
            class="btn btn-sm btn-primary"
            >New Valuation</router-link
          >

          <router-link
            :to="{ name: 'my valuations' }"
            class="btn btn-sm btn-primary"
            >See all Valuations</router-link
          >
        </div-->
      </div>
    </div>
    <div class="table-responsive">
      <base-table
        thead-classes="thead-light"
        :data="valuations"
      >
        <template slot="columns">
          <th>Address / Transaction - Type,Rooms,Baths,Surface</th>
          <th>Updated</th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <router-link :to="{ name: 'view valuation', params: { id: row.id } }">
            {{ row.address }}
            </router-link>
            <br/>{{ row.purpose.toUpperCase() }} 
            - {{ row.type }},{{ row.rooms }},{{ row.baths }},{{ row.area }}
          </th>
          <td>
            {{ formatDate(row.updatedAt) }}
          </td>          
        </template>
      </base-table>
    </div>

    <div class="col">
      <br/><br/>
          <router-link
            :to="{ name: 'create valuation' }"
            class="btn btn-sm btn-primary"
            >New Valuation</router-link
          >
          <router-link
            :to="{ name: 'my valuations' }"
            class="btn btn-sm btn-primary"
            >See all Valuations</router-link
          >
          <br/><br/>
    </div>
  </div>

</template>
<script>
export default {
  name: "my-valuations",
  props: ["valuations"],
  computed: {
 
    nodataMessage() {
      return "No valuations registered."
    },
  },
  methods: {
      formatDate(sdate) {
        return sdate.substring(0,16).replace("T"," ");
      }
  },
};
</script>
<style></style>
